.ntf-box-container {
    display: block;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    @include prefixit(box-sizing, border-box);
    @include prefixit(transition, all 0.4s ease);
    @include prefixit(transform, translateY(-100%));
    z-index: 999999999;

    &.ntf-visible {
        @include prefixit(transform, translateY(0));
    }

    .ntf-box {
        display: block;
        width: 100%;
        height: auto;
        @include prefixit(box-sizing, border-box);
        position: relative;

        .ntf-message {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: auto;
            min-height: 50px;
            padding: 0 15px;
            background: #000000;
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            color: #ffffff;
            cursor: pointer;
            @include prefixit(box-sizing, border-box);

            &.ntf-message-success {
                background: #1dd1a1;
            }

            &.ntf-message-error {
                background: #ff6b6b;
            }

            &.ntf-message-success,
            &.ntf-message-error {
                & + .ntf-progress {
                    .ntf-progress-bar {
                        background: rgba(#000000, 0.2);
                    }
                }
            }

            @supports ((-webkit-backdrop-filter: blur(0)) or (backdrop-filter: blur(0))) {
                @include prefixit(backdrop-filter, blur(15px));
                background: rgba(#000000, 0.85);

                &.ntf-message-success {
                    background: rgba(#1dd1a1, 0.85);
                }

                &.ntf-message-error {
                    background: rgba(#ff6b6b, 0.85);
                }
            }
        }

        .ntf-progress {
            display: block;
            width: 100%;
            height: 3px;
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;

            .ntf-progress-bar {
                display: block;
                width: 0;
                height: 100%;
                background: rgba(#ffffff, 0.35);
                @include prefixit(transition, all 0.1s);
            }
        }
    }
}
