@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Variables
$font-size: 16px;

// Fonts
$font-family-rubik: 'Rubik', sans-serif;

// Colors
$blue-color: #12304a;
$gray-color: #c9bdb5;

// Imports
@import './assets/scss/_mixins';
@import './assets/scss/_normalize';
@import './assets/scss/_notifyit';

@include keyframes(rotate) {
    from {
        @include prefixit(transform, rotate(0deg));
    } // from
    to {
        @include prefixit(transform, rotate(360deg));
    } // to
} // @include keyframes(rotate)

:root {
    font-size: $font-size; // 1rem
} // :root

html {
    @include prefixit(scroll-behavior, smooth);
} // html

body {
    display: flex;
    width: 100%;
    height: auto;
    min-height: 100vh;
    margin: 0;
    padding: 0 2rem;
    background-image: url('./assets/img/bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-family: $font-family-rubik;
    font-weight: 400;
    font-size: $font-size;
    color: $gray-color;

    @media (max-width: 768px) {
        padding: 4rem 2rem;
    } // @media (max-width: 768px)

    &::before {
        content: "";
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($blue-color, 0.85);
    } // &::before

    @media (max-width: 480px) {
        display: block;
        height: auto;
    } // @media (max-width: 480px)

    // Helpers
    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    } // h1, h2, h3, h4, h5, h6, p

    a {
        text-decoration: none;
        color: inherit;
    } // a

    .ri {
        display: inline-block;
        font-weight: 100;
        font-size: 24px;

        &.ri--spin {
            @include prefixit(animation, rotate 2s linear infinite);
            margin: 0 !important;
        } // &.ri--spin
    } // .ri

    #root {
        display: block;
        width: 100%;
        max-width: 1200px;
        height: auto;
        margin: auto;
        position: relative;

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 88px 64px;
            width: 100%;
            height: auto;

            @media (max-width: 768px) {
                gap: 64px;
            } // @media (max-width: 768px)

            .main,
            .aside {
                display: block;
                width: calc((100% / 2) - (64px / 2));
                height: auto;

                @media (max-width: 768px) {
                    width: 100%;
                } // @media (max-width: 768px)
            } // .aside

            .main {
                .logo {
                    display: block;
                    width: auto;
                    height: 112px;

                    @media (max-width: 768px) {
                        margin: 0 auto;
                    } // @media (max-width: 768px)
                } // .logo

                .description {
                    display: block;
                    margin: 48px 0 0;

                    p {
                        display: block;
                        margin: 0 0 16px;
                        line-height: 133%;
                        color: rgba($gray-color, 0.66);
                    } // p
                } // .description
            } // .main

            .aside {
                .contact-form {
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                    width: 100%;
                    height: auto;

                    .form-input,
                    .form-textarea {
                        @include prefixit(appearance, none);
                        @include transition();
                        display: block;
                        width: 100%;
                        height: 48px;
                        margin: 0;
                        padding: 0 16px;
                        // background-color: darken(white, 16);
                        // background-color: $gray-color;
                        background-color: rgba($blue-color, 1);
                        border: none;
                        border-bottom: 1px solid $gray-color;
                        border-radius: 0;
                        outline: none;
                        font-family: inherit;
                        font-weight: inherit;
                        font-size: inherit;
                        color: $gray-color;

                        &:focus {
                            background-color: white;
                            color: $blue-color;
                        } // &:focus
                    } // .form-textarea

                    .form-textarea {
                        min-height: 176px;
                        padding: 16px;
                        resize: none;
                    } // .form-textarea

                    .form-submit-btn {
                        @include transition();
                        @include prefixit(appearance, none);
                        display: block;
                        width: 100%;
                        height: 48px;
                        margin: 0;
                        padding: 0 16px;
                        background-color: lighten($blue-color, 8);
                        border: none;
                        border-radius: 0;
                        outline: none;
                        font-family: inherit;
                        font-weight: 500;
                        font-size: 16px;
                        color: rgba($gray-color, 0.5);
                        cursor: pointer;

                        &:hover {
                            background-color: lighten($blue-color, 16);
                            color: $gray-color;
                        } // &:hover
                    } // .form-submit-btn
                } // .contact-form
            } // .aside

            .footer {
                display: block;
                width: 100%;
                height: auto;

                .contact-links {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 24px;
                    width: 100%;
                    margin: 0;

                    @media (max-width: 1024px) {
                        justify-content: flex-start;
                    } // @media (max-width: 1024px)

                    @media (max-width: 768px) {
                        justify-content: center;
                    } // @media (max-width: 768px)

                    .contact-link {
                        @include transition();
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        margin: 0;
                        padding: 8px 12px;
                        background-color: transparent;
                        border: 1px solid rgba(white, 0.33);
                        border-radius: 4px;
                        font-size: 14px;
                        color: white;
                        line-height: 133%;

                        .ri {
                            font-size: 20px;
                        } // .ri

                        &:hover {
                            background-color: $gray-color;
                            border: 1px solid $gray-color;
                            color: $blue-color;
                        } // &:hover
                    } // .contact-link
                } // .contact-links
            } // .footer
        } // .container
    } // #root

    .sairobo {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    } // .sairobo
} // body