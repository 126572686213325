@mixin keyframes($name)
{
	@-webkit-keyframes #{$name} {
		@content;
	}

	@-moz-keyframes #{$name} {
		@content;
	}

	@-ms-keyframes #{$name} {
		@content;
	}

	@-o-keyframes #{$name} {
		@content;
	}

	@keyframes #{$name} {
		@content;
	}
}

@mixin transition($type:all, $time:.3s, $mode:ease)
{
	-webkit-transition: $type $time $mode;
	-moz-transition: $type $time $mode;
	-ms-transition: $type $time $mode;
	-o-transition: $type $time $mode;
	transition: $type $time $mode;
}

@mixin placeholder($color)
{
	&:-webkit-input-placeholder { color: $color }
	&::-webkit-input-placeholder { color: $color }
	&:-moz-placeholder { color: $color }
	&::-moz-placeholder { color: $color }
	&:-ms-input-placeholder { color: $color }
	&::-ms-input-placeholder { color: $color }
	&:placeholder-shown { color: $color }
}

@mixin prefixit($attribute, $value)
{
	#{'-' + webkit + '-' + $attribute}: $value;
	#{'-' + moz + '-' + $attribute}: $value;
	#{'-' + ms + '-' + $attribute}: $value;
	#{'-' + o + '-' + $attribute}: $value;
	#{$attribute}: $value;
}
